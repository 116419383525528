export const openDatabase = () => {
  return new Promise<IDBDatabase>((resolve, reject) => {
    const request = indexedDB.open("DicomCacheDB", 1);

    request.onupgradeneeded = () => {
      const db = request.result;
      if (!db.objectStoreNames.contains("dicomFiles")) {
        db.createObjectStore("dicomFiles");
      }
    };

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};

export const getFromCache = async (key: string): Promise<File | null> => {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction("dicomFiles", "readonly");
    const store = transaction.objectStore("dicomFiles");
    const request = store.get(key);

    request.onsuccess = () => {
      const result = request.result;
      if (result) {
        const file = new File([result], "cached.dcm");
        resolve(file);
      } else {
        resolve(null);
      }
    };

    request.onerror = () => reject(request.error);
  });
};

export const cacheImage = async (url: string): Promise<string> => {
  const cacheName = 'image-viewer-cache';

  const cache = await caches.open(cacheName);

  // Tentar buscar a imagem do cache
  const cachedResponse = await cache.match(url);
  if (cachedResponse) {
    console.log('Imagem carregada do cache:', url);
    return url;
  }

  const response = await fetch(url);
  if (response.ok) {
    await cache.put(url, response.clone());
    console.log('Imagem armazenada no cache:', url);
    return url;
  }

  throw new Error('Erro ao buscar a imagem.');
};


export const saveToCache = async (key: string, file: Blob) => {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction("dicomFiles", "readwrite");
    const store = transaction.objectStore("dicomFiles");
    const request = store.put(file, key);

    request.onsuccess = () => resolve(true);
    request.onerror = () => reject(request.error);
  });
};
